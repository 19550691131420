export const enum TypeForm {
    EMPTY = "",

    // FREELANCERIE
    CUSTOMER = "customer",
    BILLING = "billing",
    EXPENSE = "tva",
    PERIOD = "period",
    BUSINESS = "business",
    UPLOAD_BUSINESS = "uploadBusiness",
    UPLOAD_BL = "uploadBL",
    UPLOAD_BILLING = "uploadBilling",
    UPLOAD_EXPENSE = "uploadExpense",
    UPLOAD_DEBOURS = "uploadDebours",
    STATE_DECLARATION_URSSAF = "stateDeclarationURSSAF",
    OUTLAY = "outlay",

    // AUTRE
    CONFIRM = "confirm",
    ACCOUNT = "account",
    SEND_MAIL = "send",
}
