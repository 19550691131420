import {
    useGetRequest,
    RequestResponse,
    PutRequest,
    PostRequest,
    RequestPromise,
    DeleteRequest,
} from "../../hook/useRequest";
import { AppSettings } from "../../AppSettings";
import { getToken } from "../../utils/storage/Token";
import {OutlayInterface} from "./Interfaces/OutlayInterface";

const API_URL_OUTLAY = AppSettings.API_FREELANCERIE + "/outlay";

const useFetchOutlay = (id_user: number, forceUpdated = 0): RequestResponse => {
    const config = { headers: { Authorization: getToken() } };
    return useGetRequest(`${API_URL_OUTLAY}/${id_user.toString()}`, forceUpdated, config);
};

const UpdateOutlay = (idOutlay: number, billing: unknown): RequestPromise => {
    const config = { headers: { Authorization: getToken() } };
    return PutRequest(`${API_URL_OUTLAY}/${idOutlay.toString()}`, billing, config);
};

const AddOutlay = (outlay: unknown): RequestPromise => {
    const config = { headers: { Authorization: getToken() } };
    return PostRequest(API_URL_OUTLAY, outlay, config);
};

const DeleteOutlay = (outlay: OutlayInterface): RequestPromise => {
    const config = { headers: { Authorization: getToken() } };
    return DeleteRequest(`${API_URL_OUTLAY}/${outlay.id.toString()}`, config);
};

export { useFetchOutlay, UpdateOutlay, AddOutlay, DeleteOutlay };
