import React, {useState} from "react";
import FCDatePicker from "../../../FCObjets/Form/FCDatePicker";

type InputDateTableProps = {
    edit: boolean;
    value: string|null;
    onChangeHandler?: (date: Date | null) => void;
};

const InputDateTable: React.FunctionComponent<InputDateTableProps> = (props) => {

    const [date, setDate] = useState<Date|null>(props.value ? new Date(props.value) : null);

    const onChangeHandler = (dateEvent: Date | null) => {
        setDate(dateEvent);
        if(props.onChangeHandler) props.onChangeHandler(dateEvent);
    }
    return (
        <React.Fragment>
            {
                props.edit ? (
                    <FCDatePicker onChangeHandler={onChangeHandler} value={date} id={"date"} label={""} widthLabel={"0px"}/>
                ) : props.value ? new Date (props.value).toLocaleDateString() : ""
            }
        </React.Fragment>
    );

}

export default InputDateTable;