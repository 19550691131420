export const enum TypeStatusOutlay {
    INITIALIZED = 0,
    CANCELED = 1,
    SENT = 2,
    PAID = 3
}

class StatusOutlay {
    public static ListStatus = [
        TypeStatusOutlay.INITIALIZED,
        TypeStatusOutlay.CANCELED,
        TypeStatusOutlay.SENT,
        TypeStatusOutlay.PAID
    ];

    public static toString(type: TypeStatusOutlay): string {
        switch (type) {
            case TypeStatusOutlay.INITIALIZED:
                return "Initialisé";
            case TypeStatusOutlay.CANCELED:
                return "Annulé";
            case TypeStatusOutlay.SENT:
                return "Envoyé";
            case TypeStatusOutlay.PAID:
                return "Payé";
        }
        return "Inconnu";
    }
    public static toColor(type: TypeStatusOutlay): string {
        switch (type) {
            case TypeStatusOutlay.INITIALIZED:
                return "yellow";
            case TypeStatusOutlay.CANCELED:
                return "red";
            case TypeStatusOutlay.SENT:
                return "orange";
            case TypeStatusOutlay.PAID:
                return "purple";
        }
        return "white";
    }
}

export default StatusOutlay;
