import {setToken} from "../../utils/storage/Token"
import FCButton from "../../components/FCObjets/FCButton"

import './Login.scss';
import React, {useState} from "react";
import {PostRequest} from '../../hook/useRequest';
import FCAlertMsg, {TypeAlertMsg} from "../../components/FCObjets/FCAlertMsg/FCAlertMsg";
import {AxiosError} from "axios";
import {useNavigate} from "react-router-dom";

function Login() {

    const [password, setPassword] = useState("");
    const [login, setLogin] = useState("");
    const [error, setError] = useState<any>(null);

    const navigate = useNavigate();

    const handleChange = (event: any) => {
        if (event.target.name === "password") {
            setPassword(event.target.value);
        } else if (event.target.name === "login") {
            setLogin(event.target.value);
        }
    }

    const onFinish = async (e: { preventDefault: () => void; }) => {
        e.preventDefault()
        setError(null);
        try {
            const res = await PostRequest("/authentication/login", {
                username: login,
                password: password
            });
            setToken(res.data.token);
            window.location.replace("/");
        } catch (e: any) {
            if (e instanceof AxiosError) {
                if (e.response) {
                    if (e.response.status === 403) {
                        navigate("/create_account/wait_validate");
                    }
                }
                setError(e.response ? e.response.data.error : "Erreur inconnue")
            } else {
                setError("Erreur connexion compte")
            }
        }

    };

    return (
        <div>
            <form className={"login"} method='post' onSubmit={onFinish}>
                <div className={"div_logo"}>
                    <img src={`${process.env.PUBLIC_URL}/logo_fc.svg`}
                         alt={"logo_fc"} height={100}/>
                    <h1>FREE COMPTA</h1>
                </div>
                <div style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
                    <div className={"div_authentification"}>
                        <h3>AUTHENTIFICATION</h3>
                        <div style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
                            <div style={{display: "flex", flexDirection: "column", width: "50%"}}>
                                <div className={"labelInput"}>Utilisateur</div>
                                <input className={"inputLogin"} name={"login"} type={"text"}
                                       onChange={(event) => handleChange(event)}/>
                                <div className={"labelInput"}>Mot de passe</div>
                                <input className={"inputLogin"} name={"password"} type={"password"}
                                       onChange={(event) => handleChange(event)}/>
                                <div style={{textAlign: "right"}}><a className={"labelMDPOublieEtCreationCompte"}
                                                                     href={"/"}>Mot de passe oublié</a></div>
                            </div>
                        </div>
                        <div style={{display: "flex", flexDirection: "row", justifyContent: "space-evenly"}}>
                            <FCButton type="button" label="CRÉER UN COMPTE"
                                      onClickHandler={() => window.location.replace("/create_account")}/>
                            <FCButton type="submit" label="SE CONNECTER"/>
                        </div>

                    </div>
                </div>
                {error && <FCAlertMsg textMsg={error} type={TypeAlertMsg.ERROR} activate={!!error} title={"Erreur"}/>}
            </form>
            <div className={"footerLogin"}>
                <div className={"titleFooterLogin"}>
                    <a href="mailto:yannick_marchetaux@hotmail.fr; luc.sorignet@gmail.com" className={"hrefFooterLogin"}>
                        FREE COMPTA &copy; 2023 Created by Yannick Marchetaux & Luc Sorignet
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Login;