import React, {useEffect, useState} from "react";
import "./TableForm.scss";
import {faCheck, faPen, faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import FCIconButton from "../../../FCObjets/FCIconButton";
import InputTable from "./InputTable";
import {OutlayInterface, OutlayItemInterface} from "../../../../service/Business/Interfaces/OutlayInterface";
import InputFileTable from "./InputFileTable";
import InputDateTable from "./InputDateTable";

type TableOutlayItemProps = {
    dataItemOutlay: OutlayItemInterface[];
    dataOutlay: OutlayInterface;
    handlerEvent: (listOutlayItemAdd: OutlayItemInterface[],
                   listOutlayItemDel: OutlayItemInterface[],
                   listOutlayItemModify: OutlayItemInterface[]) => void;
};

const TableOutlayItem: React.FunctionComponent<TableOutlayItemProps> = (props) => {

    const [listOutlayItemTable, setListOutlayItemTable] = useState<OutlayItemInterface[]>(props.dataItemOutlay);
    const [listOutlayItemAdd, setListOutlayItemAdd] = useState<OutlayItemInterface[]>([]);
    const [listOutlayItemDel, setListOutlayItemDel] = useState<OutlayItemInterface[]>([]);
    const [listOutlayItemModify, setListOutlayItemModify] = useState<OutlayItemInterface[]>([]);

    //Row Item Form
    const [currentLabel, setCurrentLabel] = useState<string>("");
    const [currentPrice, setCurrentPrice] = useState<string>("");
    const [currentDate, setCurrentDate] = useState<Date>(new Date());

    const [file, setFile] = useState<File>();

    const [editingRowId, setEditingRowId] = useState<number | null>(null);
    const [cptAdd, setCptAdd] = useState<number>(-1);

    useEffect(() => {
        let listTmp: OutlayItemInterface [] = [...props.dataItemOutlay, ...listOutlayItemAdd]

        // Suppression de l'affichage des contacts supprimés (en BDD)
        for (let i = 0; i < listOutlayItemDel.length; i++) {
            listTmp = listTmp.filter((row) => (row.id !== listOutlayItemDel[i].id));
        }

        for (let i = 0; i < listOutlayItemModify.length; i++) {
            listTmp = listTmp.map((row) => {
                if (row.id === listOutlayItemModify[i].id) {
                    return {
                        ...row,
                        label: listOutlayItemModify[i].label,
                        price: listOutlayItemModify[i].price,
                        date: listOutlayItemModify[i].date,
                        file: listOutlayItemModify[i].file,
                        receiptLoaded: listOutlayItemModify[i].receiptLoaded,
                        nameFile: listOutlayItemModify[i].nameFile
                    }
                }
                return row;
            })
        }
        setListOutlayItemTable(listTmp);
        props.handlerEvent(listOutlayItemAdd, listOutlayItemDel, listOutlayItemModify);

    }, [listOutlayItemAdd, listOutlayItemDel, listOutlayItemModify]);

    /**
     * Ajout d'un item de débours dans le tableau
     */
    const addOutlayItem = () => {
        const newOutlay: OutlayItemInterface = {
            id: cptAdd,
            price: 0,
            label: "Nouveau frais",
            date: new Date().toDateString(),
            file: undefined,
            receiptLoaded: false,
            nameFile: ""
        }
        setListOutlayItemAdd((listOutlayItemAdd) => [...listOutlayItemAdd, newOutlay]);
        setCptAdd((cptAdd) => cptAdd - 1);
    }

    /**
     * Suppression d'un item de facturation dans le tableau
     */
    const delOutlayItem = (OutlayItem: OutlayItemInterface) => {
        if (OutlayItem.id <= -1) {
            // Contact fraichement ajouté, on le supprime de la liste des ajouts
            setListOutlayItemAdd((listOutlayItemAdd) => listOutlayItemAdd.filter((row) => (row.id !== OutlayItem.id)));
        } else {
            // On l'ajoute dans une liste
            setListOutlayItemDel((listOutlayItemDel) => [...listOutlayItemDel, OutlayItem]);
        }
    }

    /**
     * Mise à jour de la valeur
     */
    const handleSaveRow = (id: number, price: number, label: string, date: Date, file: File | undefined) => {
        let existOnModify = listOutlayItemModify.filter((outlayItem => outlayItem.id === id));
        let existOnAdd = listOutlayItemAdd.filter((outlayItem => outlayItem.id === id));

        if (existOnAdd.length > 0) {
            let r = listOutlayItemAdd.map((row) => {
                if (row.id === id) {
                    let newRow: OutlayItemInterface = {
                        ...row, price: price, label: label, date: date.toDateString(), file: file
                    };
                    return newRow;
                }
                return row;
            });

            setListOutlayItemAdd(r);
        } else if (existOnModify.length > 0) {
            let r = listOutlayItemModify.map((row) => {
                if (row.id === id) {
                    let newRow: OutlayItemInterface = {
                        ...row, price, label: label, date: date.toDateString(), file: file
                    };
                    return newRow;
                }
                return row;
            });

            setListOutlayItemModify(r);
        } else {
            // On vérifie qu'il ne fait pas partie des données ajoutées.
            listOutlayItemTable.map((row) => {
                if (row.id === id) {
                    let newRow: OutlayItemInterface = {
                        ...row, price, label: label, date: date.toDateString(), file: file
                    };
                    setListOutlayItemModify((listOutlayItemModify) => [...listOutlayItemModify, newRow]);
                    return newRow;
                }
                return row;
            });
        }
    };

    return (
        <React.Fragment>
            <FCIconButton icon={faPlus} tooltip={"Ajouter Element"} color="green" onClickHandler={addOutlayItem}
                          style={{alignSelf: "flex-end"}}/>
            <div style={{marginBottom: 0, display: "contents"}}>
                <table className={"tableForm table"} style={{marginBottom: 0, tableLayout: "auto"}}>
                    <thead>
                    <tr>
                        <th>Description</th>
                        <th>Date</th>
                        <th>Prix TTC</th>
                        <th style={{width: "250px"}}>Justificatif</th>
                        <th>
                            <div className="row-content">
                                Actions &nbsp;
                            </div>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        listOutlayItemTable.map((e: OutlayItemInterface, index: React.Key) => {
                            return (
                                <tr key={index}>
                                    <td><InputTable edit={editingRowId === e.id}
                                                    value={editingRowId === e.id ? currentLabel : e.label}
                                                    onChangeHandler={(event) => {
                                                        const value = event.target.value
                                                        setCurrentLabel(value);
                                                        handleSaveRow(e.id, e.price, value, new Date(e.date), file);
                                                    }}/>
                                    </td>
                                    <td><InputDateTable edit={editingRowId === e.id}
                                                        value={editingRowId === e.id ? currentDate.toDateString() : e.date}
                                                        onChangeHandler={(event) => {
                                                            const value = event
                                                            if (value) {
                                                                setCurrentDate(value);
                                                                handleSaveRow(e.id, e.price, e.label, value, file);
                                                            }
                                                        }}/>
                                    </td>
                                    <td><InputTable edit={editingRowId === e.id}
                                                    value={editingRowId === e.id ? currentPrice : e.price} type={"text"}
                                                    width={"100px"} step={"any"} pattern="[0-9]+([,\.][0-9]*)?"
                                                    onChangeHandler={(event) => {
                                                        const value = event.target.value.replaceAll(',', ".");
                                                        const numberVal = parseFloat(value);
                                                        if (!isNaN(numberVal)) {
                                                            setCurrentPrice(value);
                                                            handleSaveRow(e.id, numberVal, e.label, new Date(e.date), file);
                                                        } else setCurrentPrice(value);
                                                    }}/>
                                        &nbsp;&euro;
                                    </td>
                                    <td><InputFileTable edit={editingRowId === e.id}
                                                        nameOutlay={props.dataOutlay.label} outlayItem={e}
                                                        onChangeHandler={(event) => {
                                                            if (event.target.files) {
                                                                setFile(event.target.files[0]);
                                                                handleSaveRow(e.id, e.price, e.label, new Date(e.date), event.target.files[0]);
                                                            }
                                                        }}/>
                                    </td>
                                    <td>
                                        {
                                            editingRowId === e.id ?
                                                (<FCIconButton icon={faCheck} color="green" tooltip={"Valider"}
                                                               onClickHandler={() =>
                                                                   setEditingRowId(null)}/>
                                                ) : (
                                                    <FCIconButton icon={faPen} color="green" tooltip={"Modifier"}
                                                                  onClickHandler={() => {
                                                                      setCurrentLabel(e.label);
                                                                      setCurrentPrice(e.price.toString().replaceAll(",", '.'));
                                                                      setCurrentDate(new Date(e.date));
                                                                      setFile(undefined);
                                                                      setEditingRowId(e.id);
                                                                  }}/>)
                                        }
                                        <FCIconButton icon={faTrash} color="red" tooltip={"Supprimer"}
                                                      onClickHandler={() => delOutlayItem(e)}/>
                                    </td>
                                </tr>
                            )
                        })
                    }
                    </tbody>
                </table>
            </div>
        </React.Fragment>
    );
}

export default TableOutlayItem;