import React, {useState} from "react";
import "./Dashboard.scss"
import {
    isAdminWithToken,
    getToken,
    getUserNameWithToken,
    verifyToken
} from "../../utils/storage/Token";
import Login from "../Login/Login";
import ContentCalendar from "../../components/Business/Calendar/ContentCalendar";
import HeaderDashboard from "../../components/Dashboard/HeaderDashboard/HeaderDashboard";
import {EventUpdatedProvider} from "../../store/EventUpdatedContext";
import ContentCustomer from "../../components/Business/Customer/ContentCustomer";
import FooterDashboard from "../../components/Dashboard/FooterDashboard/FooterDashboard"
import ContentBusinessDeal from "../../components/Business/BusinessDeal/ContentBusinessDeal";
import ContentBilling from "../../components/Business/Billing/ContentBilling";
import ContentHome from "../../components/Dashboard/ContentFC/ContentHome/ContentHome";
import ContentAccount from "../../components/Dashboard/ContentFC/ContentAccount/ContentAccount";
import ContentAddress from "../../components/Business/AddressBook/ContentAddress";
import ContentModifyPass from "../../components/Dashboard/ContentFC/ContentModifyPass/ContentModifyPass";
import ExplorerFiles from "../../components/Business/ExplorerFiles/ExplorerFiles";
import ContentAdminUsers from "../../components/Dashboard/Administration/ContentAdminUsers/ContentAdminUsers";
import ContentResumeDeclaration
    from "../../components/Business/Declaration/ContentResumeDeclaration/ContentResumeDeclaration";
import ContentDeclarationURSSAF
    from "../../components/Business/Declaration/ContentDeclarationURSSAF/ContentDeclarationURSSAF";
import ContentDeclarationTVA from "../../components/Business/Declaration/ContentDeclarationTVA/ContentDeclarationTVA";
import ContentHelpDeclaration from "../../components/Business/Declaration/ContentHelp/ContentHelpDeclaration";
import ContentStatistic from "../../components/Business/Statistic/ContentStatistic";
import ContentOutlay from "../../components/Business/Outlay/ContentOutlay";

function Dashboard() {

    const [menuSelected, setMenuSelected] = useState("");

    verifyToken();
    if (!getToken()) {
        return (
            <Login/>
        )
    }

    return (
        <div className={"dashboard"}>
            <HeaderDashboard setMenuSelected={setMenuSelected}/>
            <EventUpdatedProvider>
                <div className={"contentDashboard"}>
                    <div className={"absolute layoutContent"}>
                        {((menuSelected && menuSelected === "calendar") &&
                                <ContentCalendar editable={true} username={getUserNameWithToken()}/>) ||
                            ((menuSelected && menuSelected === "customer") &&
                                <ContentCustomer/>) ||
                            ((menuSelected && menuSelected === "businessDeal") &&
                                <ContentBusinessDeal/>) ||
                            ((menuSelected && menuSelected === "billing") &&
                                <ContentBilling/>) ||
                            ((menuSelected && menuSelected === "outlay") &&
                                <ContentOutlay/>) ||
                            ((menuSelected && menuSelected === "config") &&
                                <ContentAccount/>) ||
                            ((menuSelected && menuSelected === "password") &&
                                <ContentModifyPass/>) ||
                            ((menuSelected && menuSelected === "address") &&
                                <ContentAddress/>) ||
                            ((menuSelected && menuSelected === "explorer") &&
                                <ExplorerFiles />) ||
                            ((menuSelected && menuSelected === "adminusers" && isAdminWithToken()) &&
                                <ContentAdminUsers />) ||
                            ((menuSelected && menuSelected === "resume_dec") &&
                                <ContentResumeDeclaration />) ||
                            ((menuSelected && menuSelected === "urssaf") &&
                                <ContentDeclarationURSSAF />) ||
                            ((menuSelected && menuSelected === "tva") &&
                                <ContentDeclarationTVA />) ||
                            ((menuSelected && menuSelected === "help_declaration") &&
                                <ContentHelpDeclaration />) ||
                            ((menuSelected && menuSelected === "stat") &&
                                <ContentStatistic />) ||
                            <ContentHome/>
                        }
                    </div>
                </div>
            </EventUpdatedProvider>
            <FooterDashboard/>
        </div>
    )
}

export default Dashboard;