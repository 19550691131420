import React from 'react';

import {useNavigate} from "react-router-dom";
import FCButton from '../../components/FCObjets/FCButton';

import './WaitValidate.scss';
const WaitValidate: React.FunctionComponent = () => {

    const navigate = useNavigate();
    return (
    <React.Fragment>
        <div className={"waitValidate"}>
            <div className={"div_logo"}>
                <img src={ `${process.env.PUBLIC_URL}/logo_fc.svg`}
                     alt={"logo_fc"} height={100}/>
            </div>
            <div style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
                <div className={"div_message"}>
                    <h3>Compte en attente de validation</h3>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
                        <p>Veuillez attendre la validation du compte par un administrateur. Merci.</p>
                    </div>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
                       <FCButton  onClickHandler={()=>navigate("/")} label="Connexion"/>
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>
 )
}

export default WaitValidate;