import React, {useEffect, useState} from "react";
import "./TableForm.scss";
import {ContactInterface} from "../../../../service/Business/Interfaces/ContactInterface";
import {faCheck, faPen, faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import FCIconButton from "../../../FCObjets/FCIconButton";
import InputTable from "./InputTable";
import TypeContact from "../../../../utils/Business/TypeContact";

type TableContactCustomerProps = {
    dataContact: ContactInterface[];
    handlerEvent: (listContactAdd: ContactInterface[], listContactDel: ContactInterface[], listContactModify: ContactInterface[]) => void;
};

const TableContactCustomer: React.FunctionComponent<TableContactCustomerProps> = (props) => {

    const [listContactTable, setListContactTable] = useState<ContactInterface[]>(props.dataContact);
    const [listContactAdd, setListContactAdd] = useState<ContactInterface[]>([]);
    const [listContactDel, setListContactDel] = useState<ContactInterface[]>([]);
    const [listContactModify, setListContactModify] = useState<ContactInterface[]>([]);

    const [editingRowId, setEditingRowId] = useState<number | null>(null);
    const [cptAdd, setCptAdd] = useState<number>(-1);

    // Création des options pour la liste des status
    let optionsDrop: { label: string, value: string }[] = [];
    for (let i = 0; i < TypeContact.ListType.length; i++) {
        optionsDrop.push({
            value: TypeContact.ListType[i].toString(),
            label: TypeContact.toString(TypeContact.ListType[i])
        });
    }

    useEffect(() => {
        let listTmp: ContactInterface [] = [...props.dataContact, ...listContactAdd]

        // Suppression de l'affichage des contacts supprimés (en BDD)
        for (let i = 0; i < listContactDel.length; i++) {
            listTmp = listTmp.filter((row) => (row.id !== listContactDel[i].id));
        }

        for (let i = 0; i < listContactModify.length; i++) {
            listTmp = listTmp.map((row) => {
                if (row.id === listContactModify[i].id) {
                    return {
                        ...row,
                        name: listContactModify[i].name,
                        phone: listContactModify[i].phone,
                        address: listContactModify[i].address,
                        type: listContactModify[i].type
                    }
                }
                return row;
            })
        }
        setListContactTable(listTmp);
        props.handlerEvent(listContactAdd, listContactDel, listContactModify);

    }, [listContactAdd, listContactDel, listContactModify]);

    /**
     * Ajout d'un contact dans le tableau
     */
    const addContact = () => {
        const newContact: ContactInterface = {
            address: "Adresse",
            id: cptAdd,
            name: "Nom",
            phone: "Téléphone",
            type: 0
        }
        setListContactAdd((listContactAdd) => [...listContactAdd, newContact]);
        setCptAdd((cptAdd) => cptAdd - 1);
    }

    /**
     * Suppression d'un contact dans le tableau
     */
    const delContact = (contact: ContactInterface) => {
        if (contact.id <= -1) {
            // Contact fraichement ajouté, on le supprime de la liste des ajouts
            setListContactAdd((listContactAdd) => listContactAdd.filter((row) => (row.id !== contact.id)));
        } else {
            // On l'ajoute dans une liste
            setListContactDel((listContactDel) => [...listContactDel, contact]);
        }
    }

    /**
     * Mise à jour de la valeur
     */
    const handleSaveRow = (id: number, newName: string, newPhone: string, newAddress: string, type: number) => {
        let existOnModify = listContactModify.filter((contact => contact.id === id));
        let existOnAdd = listContactAdd.filter((contact => contact.id === id));

        if (existOnAdd.length > 0) {
            let r = listContactAdd.map((row) => {
                if (row.id === id) {
                    let newRow: ContactInterface = {
                        ...row, name: newName, phone: newPhone, address: newAddress, type: type
                    };
                    return newRow;
                }
                return row;
            });

            setListContactAdd(r);
        } else if (existOnModify.length > 0) {
            let r = listContactModify.map((row) => {
                if (row.id === id) {
                    let newRow: ContactInterface = {
                        ...row, name: newName, phone: newPhone, address: newAddress, type: type
                    };
                    return newRow;
                }
                return row;
            });

            setListContactModify(r);
        } else {

        setListContactTable((listContactTable) =>
            listContactTable.map((row) => {
                if (row.id === id) {
                    let newRow: ContactInterface = {
                        ...row,
                        name: newName,
                        phone: newPhone,
                        address: newAddress,
                        type: type
                    };
                    setListContactModify((listContactModify) => [...listContactModify, newRow]);
                    return newRow;
                }
                return row;
            })
        );}
    };

    return (
        <React.Fragment>
        <FCIconButton icon={faPlus} tooltip={"Ajouter Contact"}  color="green"  onClickHandler={addContact} style={{alignSelf:"flex-end"}}/>
        <div style={{marginBottom: 0}}>
           <table className={"tableForm table"} style={{marginBottom: 0, tableLayout: "auto"}}>
            <thead>
            <tr>
                <th>Nom</th>
                <th>Téléphone</th>
                <th>Mail</th>
                <th>Type</th>
                <th>
                    <div className="row-content">
                        Actions
                    </div>
                </th>
            </tr>
            </thead>
            <tbody>
            {
                listContactTable.map((e: ContactInterface, index: React.Key) => {
                    return (
                        <tr key={index}>
                            <td><InputTable edit={editingRowId === e.id} value={e.name}
                                            onChangeHandler={(event) =>
                                                       handleSaveRow(e.id, event.target.value, e.phone, e.address, e.type)}/>
                            </td>
                            <td><InputTable edit={editingRowId === e.id} value={e.phone}
                                            onChangeHandler={(event) =>
                                                       handleSaveRow(e.id, e.name, event.target.value, e.address, e.type)}/>
                            </td>
                            <td><InputTable edit={editingRowId === e.id} value={e.address}
                                            onChangeHandler={(event) =>
                                                       handleSaveRow(e.id, e.name, e.phone, event.target.value, e.type)}/>
                            </td>
                            <td>
                                {
                                    editingRowId === e.id ?
                                        (<select className={"FCDropdown"} value={e.type} onChange={(event) =>
                                            handleSaveRow(e.id, e.name, e.phone, e.address, parseInt(event.target.value))}>
                                            {
                                                optionsDrop.map((option) => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))
                                            }
                                        </select>) : (TypeContact.toString(e.type))
                                }
                            </td>
                            <td>
                                {
                                    editingRowId === e.id ?
                                        (<FCIconButton icon={faCheck} tooltip={"Valider"} color="green"
                                                       onClickHandler={() =>
                                                           setEditingRowId(null)}/>
                                        ) : (
                                            <FCIconButton icon={faPen} tooltip={"Modifier"} color="green"
                                                          onClickHandler={() => setEditingRowId(e.id)}/>)
                                }
                                <FCIconButton icon={faTrash} tooltip={"Supprimer"} color="red"
                                              onClickHandler={() => delContact(e)}/>
                            </td>
                        </tr>
                    )
                })
            }
            </tbody>
        </table>
        </div>

        </React.Fragment>
    );
}

export default TableContactCustomer;