import { PostRequest, DeleteRequest, RequestPromise, PutRequest } from "../../hook/useRequest";
import { AppSettings } from "../../AppSettings";
import { getToken } from "../../utils/storage/Token";
import {OutlayItemInterface} from "./Interfaces/OutlayInterface";

const API_URL_OUTLAY_ITEM = AppSettings.API_FREELANCERIE + "/outlay/item";

const UpdateOutlayItem = (
    idOutlay: number,
    outlayItem: { listOutlayItem: OutlayItemInterface[] },
): RequestPromise => {
    const config = { headers: { Authorization: getToken() } };
    return PutRequest(`${API_URL_OUTLAY_ITEM}/${idOutlay.toString()}`, outlayItem, config);
};

const AddOutlayItem = (idOutlay: number, bilItem: { listOutlayItem: OutlayItemInterface[] }): RequestPromise => {
    const config = { headers: { Authorization: getToken() } };
    return PostRequest(`${API_URL_OUTLAY_ITEM}/${idOutlay.toString()}`, bilItem, config);
};

const DeleteOutlayItem = (outlayItem: OutlayItemInterface): RequestPromise => {
    const config = { headers: { Authorization: getToken() } };
    return DeleteRequest(`${API_URL_OUTLAY_ITEM}/${outlayItem.id.toString()}`, config);
};

const UploadOutlayItem = (idOutlayItem: number, file: File): RequestPromise => {
    const formData = new FormData();
    formData.append(file.name, file);
    const config = {
        headers: {
            Authorization: getToken(),
            "content-type": file.type,
        },
    };
    return PostRequest(`${API_URL_OUTLAY_ITEM}/upload/${idOutlayItem}`, formData, config);
};

export { UpdateOutlayItem, AddOutlayItem, DeleteOutlayItem, UploadOutlayItem };
