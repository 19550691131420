import React, {useContext, useState} from "react";
import "./ContentOutlay.scss";
import {EventUpdatedContext} from "../../../store/EventUpdatedContext";
import {getIdWithToken} from "../../../utils/storage/Token";
import FCSpinner from "../../FCObjets/FCSpinner";
import {faFilePdf, faPen, faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import FCIconButton from "../../FCObjets/FCIconButton";
import {TypeForm} from "../../../types/EnumTypeForm";
import FCAlertMsgErrorAPI from "../../FCObjets/FCAlertMsg/FCAlertMsgErrorAPI";
import FCContentModalFL, {DataForm} from "../../FCObjets/Form/FCContentModalFL";
import FCFloatButton from "../../FCObjets/FCFloatButton";
import FCModal from "../../FCObjets/FCModal";
import {DeleteOutlay, useFetchOutlay} from "../../../service/Business/OutlayService";
import {OutlayInterface} from "../../../service/Business/Interfaces/OutlayInterface";
import FCTag from "../../FCObjets/FCTag";
import StatusOutlay from "../../../utils/Business/StatusOutlay";

function ContentOutlay() {

    let {cptUpdated, setUpdated} = useContext(EventUpdatedContext);
    const {data: outlays, loading, error} = useFetchOutlay(getIdWithToken(), cptUpdated);
    const [isModalOpened, setIsModalOpened] = useState(false)

    const [data, setData] = useState<DataForm>({
        type: TypeForm.EMPTY,
        data: undefined,
    });

    const [confirmIsOpen] = useState(false);
    const [confirmMessage] = useState("");


    // Permettant l'ouverture du modal
    const gestionModal = (open: boolean, e: OutlayInterface | any | undefined, type: TypeForm | undefined) => {
        setIsModalOpened(open)
        let t = TypeForm.EMPTY;
        if (open && type) {
            t = type
        }

        let r: DataForm = {
            type: t,
            data: e
        };

        setData(r)
    }

    return (
        <FCContentModalFL form={data} onModalFormFinished={() => {
            gestionModal(false, undefined, undefined);
            setUpdated();
        }}>
            <div className={"content-page-billing"}>
                <div className={"div-title-billing"}>
                    <h1 className="content-heading">
                        Frais de déplacement
                    </h1>
                </div>
                {
                    !isModalOpened && <FCFloatButton items={[]} icon={faPlus} positionBottom={"70px"}
                                                     handlerOnClick={() => gestionModal(true, undefined, TypeForm.OUTLAY)}/>
                }
                <table className={"table"}>
                    <thead>
                    <tr>
                        <th>Description</th>
                        <th>Status</th>
                        <th>Facture associée</th>
                        <th>Total</th>
                        <th>
                            <div className="row-content">
                                Actions
                            </div>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        loading && <tr>
                            <td><FCSpinner size={"middle"}/></td>
                        </tr>
                    }
                    {
                        outlays && outlays.map((e: OutlayInterface, index: React.Key) => {
                            let total = 0;
                            if (e.outlayItems) {
                                total = e.outlayItems.reduce((accumulator, bit) => {
                                    return accumulator + bit.price
                                }, 0);
                            }

                            return (<tr key={index}>
                                <td data-label="Description">{e.label}</td>
                                <td data-label="Status"><FCTag value={StatusOutlay.toString(e.state)}
                                                               color={StatusOutlay.toColor(e.state)}
                                                               style={{fontWeight: "bold"}}/></td>
                                <td data-label="Facture associée">{e.billing ? e.billing.number : ""}</td>
                                <td data-label="Total">{total} &euro;</td>
                                <td data-label="Actions">
                                    <div className="row-content">
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            flexWrap: "wrap",
                                            justifyItems: "left",
                                            width: "100%",
                                            justifyContent: "center"
                                        }}>
                                            <FCIconButton icon={faPen} tooltip={"Modifier"} color={"green"}
                                                          onClickHandler={() => {
                                                              gestionModal(true, e, TypeForm.OUTLAY)
                                                          }}/>
                                            <FCIconButton icon={faFilePdf} tooltip={"Générer débours"} color={"blue"}
                                                          onClickHandler={() => {
                                                              window.open(`/pdf/outlay/${e.id}`, '_blank')
                                                              setTimeout(() => setUpdated(), 3000);
                                                          }}/>
                                            <FCIconButton icon={faTrash} tooltip={"Supprimer"} color={"red"}
                                                          onClickHandler={() => {
                                                              const onConfirm = () => {
                                                                  DeleteOutlay(e).then(() => setUpdated())
                                                              }
                                                              const payload = {
                                                                  title: `Suppression de la note de frais ${e.label}`,
                                                                  msg: "Êtes-vous sûr de vouloir supprimer cette note de frais ?",
                                                                  onConfirm: onConfirm
                                                              }
                                                              gestionModal(true, payload, TypeForm.CONFIRM);
                                                          }}/>

                                        </div>
                                    </div>
                                </td>
                            </tr>)
                        })
                    }
                    {
                        (error) && <tr>
                            <td>
                                <div>{error.message}</div>
                                <FCAlertMsgErrorAPI error={error}/>
                            </td>
                        </tr>
                    }

                    </tbody>
                </table>

            </div>
            <FCModal isOpen={confirmIsOpen} onClose={function (): void {
                throw new Error("Function not implemented.");
            }}>
                {confirmMessage}

            </FCModal>
        </FCContentModalFL>
    );
}

export default ContentOutlay;