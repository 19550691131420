export const enum TypeStatusAccountUser {
    NEW = 0,
    AUTHORIZED = 1,
    BLOCKED = 2,
}

class StatusAccountUser {
    public static ListStatus = [
        TypeStatusAccountUser.NEW,
        TypeStatusAccountUser.AUTHORIZED,
        TypeStatusAccountUser.BLOCKED,
    ];

    public static toString(type: TypeStatusAccountUser): string {
        switch (type) {
            case TypeStatusAccountUser.NEW:
                return "Nouveau";
            case TypeStatusAccountUser.AUTHORIZED:
                return "Autorisé";
            case TypeStatusAccountUser.BLOCKED:
                return "Bloqué";
        }
    }

    public static toColor(type: TypeStatusAccountUser): string {
        switch (type) {
            case TypeStatusAccountUser.NEW:
                return "blue";
            case TypeStatusAccountUser.AUTHORIZED:
                return "green";
            case TypeStatusAccountUser.BLOCKED:
                return "red";
        }
    }
}

export default StatusAccountUser;
