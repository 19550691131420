import React from "react";
import "./ContentResumeTVA.scss";

type ContentResumeTVAProps = {
    vatMonth: number;
    vatSum: number;
    vatDeclared: number;
};

const ContentResumeTVA: React.FunctionComponent<ContentResumeTVAProps> = (props) => {

    return (

        <div className={"resume-tva"}>

            <div className={"line-resume-tva"}>
                <div className={"col-line-resume-tva"}>
                    TVA déductible :
                </div>
                <div className={"col-line-value-resume-tva"}>
                    {props.vatSum.toFixed(2)} €
                </div>
            </div>
            <div className={"line-resume-tva"}>
                <div className={"col-line-resume-tva"}>
                    TVA brute due :
                </div>
                <div className={"col-line-value-resume-tva"}>
                    {props.vatMonth.toFixed(2)} €
                </div>
            </div>
            <div className={"line-resume-tva"}>
                <div className={"col-line-resume-tva"}>
                    TVA nette due :
                </div>
                <div className={"col-line-value-resume-tva"}>
                    {props.vatDeclared.toFixed(2)} €
                </div>
            </div>
        </div>
    );
}

export default ContentResumeTVA;