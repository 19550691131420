import React, {useEffect, useState} from "react";
import FCInput from "../../FCObjets/Form/FCInput";
import FCButton from "../../FCObjets/FCButton";
import FCDropdown, {SelectOption} from "../../FCObjets/Form/FCDropdown";
import {getIdWithToken} from "../../../utils/storage/Token";
import FCSpinner from "../../FCObjets/FCSpinner";
import {useFetchBilling} from "../../../service/Business/BillingService";
import {CallbackErrorModalFL, CallbackFinishedModalFL} from "../../Dashboard/Form/ModalForm";
import FCAlertMsgErrorAPI from "../../FCObjets/FCAlertMsg/FCAlertMsgErrorAPI";
import {OutlayInterface, OutlayItemInterface} from "../../../service/Business/Interfaces/OutlayInterface";
import {AddOutlay, UpdateOutlay} from "../../../service/Business/OutlayService";
import {
    AddOutlayItem,
    DeleteOutlayItem,
    UpdateOutlayItem,
    UploadOutlayItem
} from "../../../service/Business/OutlayItemService";
import TableOutlayItem from "./Tables/TableOutlayItem";
import StatusOutlay from "../../../utils/Business/StatusOutlay";

type FormBillingProps = {
    onFinished: CallbackFinishedModalFL;
    onError: CallbackErrorModalFL;
    dataOutlay: OutlayInterface | undefined;
};

const FormOutlay: React.FunctionComponent<FormBillingProps> = ({dataOutlay, onFinished, onError}) => {

    const [inputLabel, setInputLabel] = useState(dataOutlay ? dataOutlay.label : "");
    const [inputBilling, setInputBilling] = useState(0);

    const [optionsOutlay, setOptionsOutlay] = useState<SelectOption[] | undefined>(undefined);

    const [listOutlayItemAdd, setListOutlayItemAdd] = useState<OutlayItemInterface[]>([]);
    const [listOutlayItemDel, setListOutlayItemDel] = useState<OutlayItemInterface[]>([]);
    const [listOutlayItemModify, setListOutlayItemModify] = useState<OutlayItemInterface[]>([]);
    const [inputState, setInputState] = useState(dataOutlay ? dataOutlay.state : 0);
    const [validate, setValidate] = useState(false);

    const {data: billings, loading, error} = useFetchBilling(getIdWithToken());

    // Création des options pour la liste des status
    const options: SelectOption[] = []
    for (let i = 0; i < StatusOutlay.ListStatus.length; i++) {
        options.push({
            value: StatusOutlay.ListStatus[i].toString(),
            label: StatusOutlay.toString(StatusOutlay.ListStatus[i])
        });
    }

    // Récupération de la liste des contrats
    useEffect(() => {
        if (billings) {
            let option: SelectOption[] = [];

            option.push({value: "-1", label: "----"});

            for (let i = 0; i < billings.length; i++) {
                option.push({value: (i+1).toString(), label: billings[i].number});
                if (billings[i].id === dataOutlay?.billing?.id) {
                    setInputBilling((i+1));
                }
            }
            setOptionsOutlay(option);
        }
    }, [billings]);

    /**
     * Gestion de l'evenement des billingitem
     * @param listAdd
     * @param listDel
     * @param listModify
     */
    const handlerEventContact = (listAdd: OutlayItemInterface[], listDel: OutlayItemInterface[], listModify: OutlayItemInterface[]) => {
        setListOutlayItemAdd(listAdd);
        setListOutlayItemDel(listDel);
        setListOutlayItemModify(listModify);
    }

    /**
     * Validation du formulaire
     */
    const handleSubmit = () => {
        onError(null);

        let billing = dataOutlay?.billing;
        if (billings) {
            billing = billings[inputBilling-1];
        }

        let outlay: {} = {
            label: inputLabel,
            billing: billing,
            outlayItems: [],
            state: inputState,
            id: dataOutlay ? dataOutlay.id : -1,
        };

        // Mise à jour des billing item
        if (listOutlayItemAdd.length !== 0) {
            AddOutlayItem(dataOutlay ? dataOutlay.id : 0, {
                listOutlayItem: listOutlayItemAdd
            })
                .then((response) => {
                    console.log('Ajout liste Outlay OK');

                    const data = response.data;
                    listOutlayItemAdd.map((item) => {
                        if(item.file) {
                            let id = -1;
                            for (let i =0; i < data.length; i++) {
                                if (data[i].label === item.label) {
                                    id = data[i].id;
                                    break;
                                }
                            }

                            UploadOutlayItem(id, item.file)
                                .then(() => console.log('Fichier ajouté'))
                                .catch((e) => console.error(e.message));
                        }
                    });
                })
                .catch((e) => console.error(e.message));
        }
        if (listOutlayItemDel.length !== 0) {
            for (let i = 0; i < listOutlayItemDel.length; i++) {
                DeleteOutlayItem(listOutlayItemDel[i])
                    .then(() => console.log('delete Outlay OK'))
                    .catch((e) => console.error(e.message));
            }
        }
        if (listOutlayItemModify.length !== 0) {
            console.log(listOutlayItemModify);
            UpdateOutlayItem(getIdWithToken(), {listOutlayItem: listOutlayItemModify})
                .then(() => {

                    console.log('Modify Outlay OK')
                    listOutlayItemModify.map((item) => {
                        if (item.file) {
                            UploadOutlayItem(item.id, item.file)
                                .then(() => console.log('Fichier mis à jour'))
                                .catch((e) => console.error(e.message));
                        }
                    })
                })
                .catch((e) => console.error(e.message));
        }

        if (dataOutlay) {
            UpdateOutlay(dataOutlay.id, outlay).catch((err) => onError(err));
        } else {
            AddOutlay(outlay).catch((err) => onError(err));
        }

        setValidate(true);
        new Promise(r => setTimeout(r, 1000)).then(() => onFinished());
    }

    return (
        <form>
            <div>
                <FCInput onChangeHandler={(e) => setInputLabel(e.target.value)} label={"Libellé"} id={"label"}
                         value={inputLabel} widthInput={"300px"} type={"text"}/>
                <React.Fragment>
                    {loading && <FCSpinner size={"small"}/>}
                    {(billings && optionsOutlay) &&
                        <FCDropdown onChangeHandler={(e) => setInputBilling(parseInt(e.target.value))}
                                    options={optionsOutlay}
                                    label={"Facture"} id={"billing"} value={inputBilling}
                                    widthDropdown={"311px"}/>}
                        <FCDropdown onChangeHandler={(e) => setInputState(parseInt(e.target.value))} options={options}
                                label={"Status"} id={"Status"} value={inputState}
                                widthDropdown={"311px"}/>
                    {error &&
                        <div>
                            {error.message}
                            <FCAlertMsgErrorAPI error={error}/>
                        </div>
                    }
                </React.Fragment>

            </div>

            {dataOutlay &&
                <TableOutlayItem dataItemOutlay={dataOutlay.outlayItems} dataOutlay={dataOutlay}
                                 handlerEvent={handlerEventContact}/>
            }
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignSelf: "flex-end",
                    alignItems: "baseline"
                }}>
                {
                    (!validate && <FCButton label={"Valider"} type={"button"} onClickHandler={handleSubmit} style={{width: "60px"}}/>) ||
                    (validate && <div style={{marginTop:"25px"}}><FCSpinner size={"middle"} /> </div>)
                }
            </div>
        </form>
    );
}

export default FormOutlay;