import React from "react";
import FCInput from "../../../FCObjets/Form/FCInput";
import {faFile, faXmark} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {AppSettings} from "../../../../AppSettings";
import {getIdWithToken, getUserNameWithToken} from "../../../../utils/storage/Token";
import {OutlayItemInterface} from "../../../../service/Business/Interfaces/OutlayInterface";

type InputFileTableProps = {
    edit: boolean;
    outlayItem: OutlayItemInterface;
    nameOutlay: string;
    onChangeHandler?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const InputFileTable: React.FunctionComponent<InputFileTableProps> = (props) => {

    const handleClickItem = () => {
        const url = `stockage_volume/${getUserNameWithToken()}/Debours/${props.nameOutlay.replaceAll(" ", "_")}/${props.outlayItem.nameFile}`
        window.open(`${AppSettings.API_ENDPOINT}${AppSettings.API_FREELANCERIE}/explorer/view/${getIdWithToken().toString()}/${encodeURIComponent(url)}`, "_blank");
    }

    return (
        <React.Fragment>
            {
                props.edit ? (
                        <FCInput onChangeHandler={props.onChangeHandler}
                                 label={""} widthLabel={"0px"}
                                 id={"fileInput"} type={"file"} value={undefined}
                                 accept={"image/png, image/jpeg, .pdf"}/>
                    ) :
                    props.outlayItem.receiptLoaded ? <FontAwesomeIcon icon={faFile} color={"#16A085"} onClick={() => {
                            handleClickItem();
                        }} style={{cursor: "pointer"}}/> :
                        <FontAwesomeIcon icon={faXmark} color={"#E74C3C"}/>
            }
        </React.Fragment>
    );

}

export default InputFileTable;