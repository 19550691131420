import React, {ChangeEvent, useState} from 'react';
import "./FormAccount.scss"
import FCInput from "../../../FCObjets/Form/FCInput";
import FCCheckbox from "../../../FCObjets/Form/FCCheckbox";

export interface InterfaceAccountEnterprise {
    companyName: string;
    companySiret: string;
    companyLocation: string;
    declarationLocation: string;
    tjm: number;
    logo: string;
    vl: boolean;
    tvaNumber: string;
}

export type AccountEnterpriseProps = {
    handlerChange: (dataUser: InterfaceAccountEnterprise) => void;
    data: InterfaceAccountEnterprise;
}

const FormAccountEnterprise: React.FC<AccountEnterpriseProps> = ({handlerChange, data}) => {

    const [dataEnterprise, setDataEnterprise] = useState<InterfaceAccountEnterprise>(data);

    const eventChange = (key: "companyName"|"companySiret"|"companyLocation"|"declarationLocation"|"tjm"|"logo"|"vl"|"tvaNumber", value:string|number|boolean) => {
        let v:any = {
            companyName: dataEnterprise.companyName,
            companySiret: dataEnterprise.companySiret,
            companyLocation: dataEnterprise.companyLocation,
            declarationLocation: dataEnterprise.declarationLocation,
            tjm: dataEnterprise.tjm,
            logo:dataEnterprise.logo,
            vl: dataEnterprise.vl,
            tvaNumber: dataEnterprise.tvaNumber
        }
        v[key] = value;
        setDataEnterprise(v);
        handlerChange(v);
    }

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const file:File = e.target.files[0];
            file.text().then((result) => eventChange("logo", result));
        }
    };

    return (
        <React.Fragment>
            {(data) && (
                <div>
                    <FCInput onChangeHandler={(e) => eventChange("companyName", e.target.value)}
                             label={"Nom de l'entreprise"}
                             id={"companyName"}
                             value={dataEnterprise.companyName} widthInput={"300px"} colorTextLabel={"#FFFFFF"}
                             widthLabel={"200px"}/>
                    <FCInput onChangeHandler={(e) => eventChange("companySiret", e.target.value)}
                             label={"N° Siret de l'entreprise "} id={"companySiret"}
                             value={dataEnterprise.companySiret} widthInput={"300px"} colorTextLabel={"#FFFFFF"}
                             widthLabel={"200px"}/>
                    <FCInput onChangeHandler={(e) => eventChange("tvaNumber", e.target.value)}
                             label={"N° TVA intracommunautaire "} id={"tva"}
                             value={dataEnterprise.tvaNumber} widthInput={"300px"} colorTextLabel={"#FFFFFF"}
                             widthLabel={"200px"}/>
                    <FCInput onChangeHandler={(e) => eventChange("companyLocation", e.target.value)}
                             label={"Adresse de l'entreprise "} id={"companyLocation"}
                             value={dataEnterprise.companyLocation} widthInput={"300px"} colorTextLabel={"#FFFFFF"}
                             widthLabel={"200px"}/>
                    <FCInput onChangeHandler={(e) => eventChange("declarationLocation", e.target.value)}
                             label={"Ville pour les Déclarations"} id={"declarationLocation"}
                             value={dataEnterprise.declarationLocation} widthInput={"300px"}
                             colorTextLabel={"#FFFFFF"}
                             widthLabel={"200px"}/>
                    <FCInput onChangeHandler={(e) => eventChange("tjm", e.target.value)}
                             label={"TJM défaut"}
                             id={"tjm"}
                             value={dataEnterprise.tjm} widthInput={"300px"} type={"number"}
                             colorTextLabel={"#FFFFFF"}
                             min={0} max={1000} widthLabel={"200px"}/>

                    <FCCheckbox onChangeHandler={(e) => eventChange("vl", e.target.checked)}
                             label={"Versement libératoire"} id={"vl"}
                             value={dataEnterprise.vl} widthLabel={"200px"}/>

                    <FCInput onChangeHandler={handleFileChange}
                             label={"Logo de l'entreprise"} id={"fileInput"} widthInput={"300px"}
                             colorTextLabel={"#FFFFFF"}
                             widthLabel={"200px"} type={"file"} value={undefined} accept=".svg" colorInput={"#FFFFFF"}/>
                    {dataEnterprise.logo &&
                        <img src={'data:image/svg+xml;base64,'+Buffer.from(dataEnterprise.logo).toString("base64")} alt={"svg"} height={70}/>}
                </div>
            )}
        </React.Fragment>
    );
};

export default FormAccountEnterprise;
